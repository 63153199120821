import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './LandingPage';
import QuizApp from './QuizApp';
import Auth from './Components/AuthPage';
import LeaderBoard from './LeaderBoard';
import SettingsPage from './settings';
import Test from './test';
import CookiePolicy from './Components/cookiePolicy';
import PrivacyPolicy from './Components/privacyPolicy';
import TermsOfService from './Components/termsAndConditions';
import './App.css'; // Import the CSS file
//import 'react-devtools'
//import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/quiz" element={<QuizApp />} />
        <Route path="/test" element={<Test />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/leaderboard" element={<LeaderBoard />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/cookiepolicy" element={<CookiePolicy />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsandconditions" element={<TermsOfService />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;